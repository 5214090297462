import styles from './contactdropdown.module.scss'
import Icons from '@components/Icons';

const ContactDropdown = ({ contactvisible, toggle }: {
    contactvisible: boolean,
    toggle: () => void
}) => {

    return (
        <div className={`
            ${styles.contactcontainer} 
            ${(contactvisible) ? styles.contactcontainershow : styles.contactcontainerhide}
        `} onClick={() => { toggle() }}>
            <p><b>The Crocodile Hunter Lodge</b></p>
            <p>
                <a href="tel:+61754362300">
                    +617 5436 2300 &nbsp;
                    <Icons iconClass={`general-telephone-fill`} />
                </a>
            </p>
            <p>
                <a href="mailto:reservations@thecrocodilehunterlodge.com.au">
                    reservations@thecrocodilehunterlodge.com.au &nbsp;
                    <Icons iconClass={`general-envelope-fill`} />
                </a>
            </p>
            <hr />
            <p><b>Warrior Restaurant {`\&`} Bar</b></p>
            <p>
                <a href="tel:+61754362310">
                    +617 5436 2310 &nbsp;
                    <Icons iconClass={`general-telephone-fill`} />
                </a>
            </p>

            <p>
                <a href="mailto:warrior@thecrocodilehunterlodge.com.au">
                    warrior@thecrocodilehunterlodge.com.au &nbsp;
                    <Icons iconClass={`general-envelope-fill`} />
                </a>
            </p>
        </div>
    )
}

export default ContactDropdown;