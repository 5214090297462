import { useCallback, useEffect, useState } from 'react'
import styles from './weather.module.scss'
import Icons from '@components/Icons'
import type { weatherData } from '@lib/types'
import { dateNowBris } from '@lib/dateTimeFunctions'

// converts celcius to fahrenhiet and returns as an integer
const converToFah = (celcius: number): number => {
    return ~~((celcius * 1.8) + 32)
}

//converts api icons to local icons
const convertIcon = (iconID: string): string => {
    let icon: string = "";
    switch (iconID) {
        case "01d":
            icon = "weather-sun-fill";
            break;
        case "01n":
        case "02n":
            icon = "weather-moon-stars-fill";
            break;
        case "02d":
        case "50d":
        case "50n":
        case "03d":
        case "03n":
            icon = "weather-cloud-sun-fill";
            break;

        case "04d":
        case "04n":
        case "13d":
        case "13n":
            icon = "weather-cloud-fill";
            break;
        case "09d":
        case "09n":
        case "10d":
        case "10n":
            icon = "weather-cloud-rain-fill";
            break;
        case "11d":
        case "11n":
            icon = "weather-lightning-charge-fill";
            break;
        default:
            icon = "weather-sun-fill";
            break;
    }
    return icon;
}

const WeatherComponent = () => {

    const DEFAULT_WEATHER_DATA: weatherData = {
        main: "Sun",
        description: "Sunshine",
        temp: 25,
        icon: "01d",
        date: 0
    }

    const [date, setDate] = useState('Mon, 1 Jan');
    const [icon, setIcon] = useState(convertIcon(DEFAULT_WEATHER_DATA.icon));
    const [description, setDescription] = useState(DEFAULT_WEATHER_DATA.description);
    const [tempBold, setTempBold] = useState(styles.celactive);
    const [tempType, setTempType] = useState(true); //true = celcius
    const [weatherData, setWeatherData] = useState({
        cel: DEFAULT_WEATHER_DATA.temp, // original celcius
        fah: converToFah(DEFAULT_WEATHER_DATA.temp), // converted fahrenhiet
    });
    const [displayTemp, setDisplayTemp] = useState(DEFAULT_WEATHER_DATA.temp); // tempreature being displayed

    const changeTemp = useCallback(() => {
        setTempType(!tempType);
        (!tempType) ? setDisplayTemp(weatherData.cel) : setDisplayTemp(weatherData.fah);
    }, [tempType, setTempType, weatherData, setDisplayTemp])

    useEffect(() => {
        (tempType) ? setTempBold(styles.celactive) : setTempBold(styles.fahactive);
    }, [tempType, setTempBold])



    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetch('/api/weatherFetchDB');
            const data: weatherData = await response.json();
            setDate(dateNowBris());
            setIcon(convertIcon(data.icon));
            setDescription(data.description);
            setWeatherData({
                cel: data.temp, // original celcius
                fah: converToFah(data.temp), // converted fahrenhiet
            })
            setDisplayTemp(data.temp)
        }
        fetchUsers();
    }, [])


    return (
        <>
            <div data-testid="weather" className={styles.container}>
                <Icons iconClass={icon} extraClass={styles.icon} alt={description} />
                <p data-testid="weather_date" className={styles.date}>{date}</p>
                <p data-testid="weather_temp" className={`${styles.temp} ${tempBold}`} onClick={() => { changeTemp() }}>
                    <span className={styles.tempnum}>{displayTemp}° </span>
                    <span className={`${styles.cel}`}>C</span>
                    <span> | </span>
                    <span className={styles.fah}>F</span></p>
                <div dangerouslySetInnerHTML={{ __html: '<!-- Weather Data Provided by openweathermap.org -->' }} />
            </div>
        </>
    )
}

export default WeatherComponent