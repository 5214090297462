import Link from 'next/link';
import styles from './footer.module.scss'
import WeatherComponent from "@components/WeatherComponent"
import Icons from '@components/Icons';
import { timeNowBris } from '@lib/dateTimeFunctions';
import { useEffect, useState } from 'react';
import { gaEvent } from '@lib/ga';

const Footer = () => {

    const [time, setTime] = useState<string>('12:00 pm');

    useEffect(() => {
        setTime(timeNowBris())
    }, [])

    return (
        <footer className={styles.footer}>
            <section className={styles.widgetsection}>
                <div className={styles.timelocationcontainer}>
                    <div className={`${styles.timewidget} ${styles.widget}`}>
                        <Icons iconClass="general-clock-fill" extraClass={styles.icon} />
                        <div className={styles.icontext}>
                            <p className={styles.time}>{time}</p>
                            <p>Local Time</p>
                        </div>
                    </div>
                    <div className={`${styles.locationwidget} ${styles.widget}`}>
                        <Icons iconClass="general-geo-alt-fill" extraClass={styles.icon} />
                        <p>88 Irwin Road, Beerwah, QLD, 4519</p>
                    </div>
                </div>
                <div className={`${styles.weatherwidget} ${styles.widget}`}>
                    <WeatherComponent />
                </div>
            </section>
            <hr />
            <section className={styles.linksection}>
                <div className={styles.socials}>
                    <p>Follow Us</p>
                    <a rel="noreferrer" target="_blank" href="https://www.facebook.com/TheCrocodileHunterLodge" title="Facebook"><Icons iconClass="social-fb" /></a>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/crocodilehunterlodge" title="Instagram"><Icons iconClass="social-in" /></a>
                    <a rel="noreferrer" target="_blank" href="https://youtube.com/australiazoo" title="Youtube"><Icons iconClass="social-yt" /></a>
                    <a rel="noreferrer" target="_blank" href="https://twitter.com/CrocHunterLodge" title="Twitter"><Icons iconClass="social-tw" /></a>
                </div>

                    <p><a href="tel:+61754362300">+617 5436 2300</a></p>
                    <p><a href="mailto:reservations@thecrocodilehunterlodge.com.au">reservations@thecrocodilehunterlodge.com.au</a></p>
                    <p><a href="mailto:warrior@thecrocodilehunterlodge.com.au">warrior@thecrocodilehunterlodge.com.au</a></p>
                    <p><Link
                        href='/documents/privacy-policy'
                        target="_blank"
                        className={styles.privacy}
                        onClick={() => { gaEvent('pdf_download', {'pdf_name': 'privacy_policy'}) }}
                        >Privacy Policy</Link></p>
                    <p><Link
                        href='/documents/terms-and-conditions'
                        target="_blank"
                        className={styles.privacy}
                        onClick={() => { gaEvent('pdf_download', {'pdf_name': 'terms_and_conditions'}) }}
                        >Terms and Conditions</Link></p>
                        

            </section>
        </footer>
    );
}

export default Footer;