import { useCallback } from 'react';
import styles from './icons.module.scss'

const Icons = ( { iconClass, extraClass, alt } : {
    iconClass: string,
    extraClass?: string,
    alt?: string
}) => {
    if (!alt) {
        alt = "";
    }
    const getIconClassName = useCallback(( name: string, extraClass?: string ) => {
        const className = `bi-${name}`;
        extraClass = (extraClass) ? ` ${extraClass}` : "";
        return `${styles.bi} ${styles[className]} ${extraClass}`;
    },[])

    return (
        <i data-testid="icon" className={getIconClassName( iconClass, extraClass )} title={alt}></i>
    )
}

export default Icons;