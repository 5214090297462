// Functions for GTM integration

// Type for datalayer parameters
type paramData = {
  [key: string]: string
}

// Declare window dataLayer type stuff so that we can fire a GTM tag without typescript errors
declare const window: Window & { dataLayer: Record<string, unknown>[]; };

/**
 * GTM datalayer event push for GTM custom event triggers
 * @param eventName string name for custom event
 * @param otherParams datalayer parameter object for any paramaters to pass through when the event fires
 */
export const gaEvent = (
  eventName: string,
  otherParams?: paramData
) => {
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  window.dataLayer.push({
    'event': eventName,
    ...otherParams
  });
}