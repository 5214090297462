import Navbar from '@components/Navbar'
import Footer from '@components/Footer'


const Layout = ({ children }:
  { children: any }
) => {
  return (
    <>
      <Navbar />
      <main style={{
        display: "flex",
        flexDirection: "column"
      }}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout; 