import { useCallback, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import Image from 'next/legacy/image'

import styles from './navbar.module.scss'
import { NAVLINKS } from '@lib/navdata'
import type { navLink } from '@lib/types'
import crocLogo from '@public/images/logo-white.png'
import Icons from '@components/Icons'
import ContactDropdown from '@components/ContactDropdown'
const SlideMenu = dynamic(() => import('@components/Slidemenu'))



const Navbar = () => {

    //set state variables
    const [visible, setVisible] = useState(false);
    const [contact, setContact] = useState(false);

    const toggleMenu = useCallback(() => {
        setVisible(!visible);
    }, [visible, setVisible]);

    const handleMouseDown = (e: any) => {
        toggleMenu();
    }

    const toggleContact = useCallback(() => {
        setContact(!contact);
    }, [contact, setContact]);

    // creates navlinks from provided data
    /* Example of navlink data array
    [
        {
            title: "Home",
            path: "/"
        },
        {
            title: "Menu 2",
            path: "/page/menu-2"
        },
    ]
    */

    const renderMenuItems = useCallback((data: navLink[]) => {
        return <>
            <li>
                <Link href={data[0].path}>
                    {data[0].title}
                </Link>
            </li>
            <span className={styles.spanLine}> | </span>
            <li>
                <Link href={data[1].path}>
                    {data[1].title}
                </Link>
            </li>
            <span className={styles.spanLine}> | </span>
            <li>
                <Link href={data[2].path}>
                    {data[2].title}
                </Link>
            </li>
            <span className={styles.spanLine}> | </span>
            <li>
                <Link href={data[3].path}>
                    {data[3].title}
                </Link>
            </li>
            <span className={styles.spanLine}> | </span>
            <li>
                <Link href={data[4].path}>
                    {data[4].title}
                </Link>
            </li>
        </>;
    }, [])

    return <>
        <nav className={styles.navbar}>
            <div className={styles.burger}>
                <button aria-label="Menu" onMouseDown={handleMouseDown} className={styles[`icon-btn`]}>
                    <Icons iconClass={`nav-list`} extraClass={`hover ${styles.burgerAlign}`} />
                </button>
                <div className={styles.logo}>
                    <Link href="/">

                        <Image
                            priority
                            src={crocLogo}
                            width={150}
                            height={35}
                            alt="Crocodile Hunter Lodge Logo"
                            placeholder='blur'
                        />

                    </Link>
                </div>
            </div>

            <div className={styles.navitems}>
                <ul>
                    {renderMenuItems(NAVLINKS)}
                </ul>
            </div>
            <div className={styles.booknow}>
                <div>
                    <Link href="https://bookings.thecrocodilehunterlodge.com.au/search-rates">
                        BOOK NOW
                    </Link>
                </div>
                <div className={styles.spanLine}>{`|`}</div>
                <button aria-label="Contact Information" className={`${styles['icon-btn']} ${styles.phone}`} onClick={() => { toggleContact() }}>
                    <Icons iconClass={`general-telephone-fill`} extraClass="hover" />
                </button>
            </div>
        </nav>
        <ContactDropdown contactvisible={contact} toggle={()=>{toggleContact()}}/>
        <SlideMenu onMouseDown={handleMouseDown} menuVisibility={visible} />
    </>;
}

export default Navbar;