import '@styles/globals.scss'
import '@public/webFonts/JuliettaMessie/juliettamessie.scss'
import 'normalize.css/normalize.css'
import type { AppProps } from 'next/app'
import Layout from '@components/Layout'
import Head from 'next/head'
import { NextPage } from 'next'
import Script from 'next/script'
import { useEffect } from 'react'

type NextPageWithMeta = NextPage & {
  title?: string,
  description?: string
}

type AppPropsWithMeta = AppProps & {
  Component: NextPageWithMeta
}

const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
}

const MyApp = ({ Component, pageProps }: AppPropsWithMeta) => {

  const title = Component.title ? `${Component.title} | The Crocodile Hunter Lodge` : `The Crocodile Hunter Lodge`;
  const description = Component.description ? `${Component.description}` : `A beautiful hideaway nestled into the Australian bush near the famed Glass House Mountains and Sunshine Coast beaches, inspired by Steve’s life, legacy and love for wild places.`;
  const logo = "https://www.thecrocodilehunterlodge.com.au/images/logo-white.png";

  // unregister old service worker causing bugs
  useEffect(() => {
    unregister()
  }, [])

  return (
    <>
      <Head>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
        <title>{title}</title>
        <meta name="description" content={description} key="description" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} key="ogTitle" />
        <meta property="og:description" content={description} key="ogDescription" />
        <meta property="og:image" content={logo} />
        <meta property='og:site_name' content='The Crocodile Hunter Lodge' />
        <meta property='og:url' content='https://www.thecrocodilehunterlodge.com.au' />

        <meta name="twitter:card" content="summary" />
        <meta name='twitter:url' content='https://www.thecrocodilehunterlodge.com.au' />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={logo} />
        <meta name="twitter:image:alt" content="The Crocodile Hunter Lodge Logo" />
        <meta name='twitter:creator' content='@CrocHunterLodge' />

        <meta name='application-name' content='The Crocodile Hunter Lodge' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
        <meta name='apple-mobile-web-app-title' content={title} />
        <meta name='format-detection' content='telephone=no' />
        <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/icons/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#000000" />
        <link rel="shortcut icon" href="/icons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileImage" content="/icons/mstile-150x150.png" />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name="theme-color" content="#c1b6a7" />


      </Head>
      {/* Google Analytics */}
      <Script
        id="gtmInlineScript"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ID}');
          `,
        }}
      />

      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  )
}

export default MyApp
